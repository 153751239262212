<template>
  <validation-observer v-slot="{ handleSubmit }" tag="div">
    <v-form @submit.prevent="handleSubmit(onSubmit)">
      <template #header>
        <v-form-row>
          <h1 class="login-owner__title">Вход для собственников</h1>
        </v-form-row>
      </template>
      <template #default>
        <v-form-row>
          <v-form-field rules="required|minValue:1000">
            <template #default="{ validationErrors }">
              <v-input-code v-model="code" :is-error="!!validationErrors.length || !!backendErrorMessage" />
            </template>
          </v-form-field>
        </v-form-row>
        <v-form-row v-if="backendErrorMessage" class="login-owner__error">
          <v-form-error>
            {{ backendErrorMessage }}
          </v-form-error>
        </v-form-row>
        <button type="button" class="login-owner__button" @click="sendCodeToOwnerAgain">
          Выслать повторно
        </button>
      </template>
      <template #footer>
        <v-button primary type="submit">Войти</v-button>
        <div class="login-owner__desc">
          Нажимая кнопку «Зарегистрироваться», вы принимаете условия
          <a class="login-owner__link" href="#">Пользовательского соглашения.</a>
        </div>
      </template>
    </v-form>
  </validation-observer>
</template>

<script>
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormError from '@/components/form/VFormError.vue'
import VFormField from '@/components/form/VFormField.vue'
import VButton from '@/components/common/VButton.vue'
import VInputCode from '@/components/common/VInputCode.vue'
import { authService } from '@/services/http'
import { ENTRY_ROUTE_NAME_FOR_REDIRECT } from '@/constants/routes'
import { loadingService } from '@/services/loading'

export default {
  name: 'SendCode',
  components: { VInputCode, VForm, VFormRow, VFormField, VFormError, VButton },
  props: {
    phone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      code: '',
      backendErrorMessage: ''
    }
  },
  methods: {
    onSubmit() {
      loadingService.setGlobalLoading(true)
      authService
        .authOwner(this.phone, this.code)
        .then(tokens => {
          authService.setTokensSync(true)
          authService.setAuthTokens(tokens)
        })
        .then(() => this.$router.push({ name: ENTRY_ROUTE_NAME_FOR_REDIRECT, params: { trackSignIn: true } }))
        .catch(error => {
          if (error.status === 400) {
            this.backendErrorMessage = 'Неверный код'
          } else {
            this.errorBackendMessage = 'Неизвестная ошибка'
            throw error
          }
        })
        .finally(() => {
          loadingService.setGlobalLoading(false)
        })
    },
    sendCodeToOwnerAgain() {
      loadingService.setGlobalLoading(true)
      authService.sendCodeToOwnerPhone(this.phone).finally(() => {
        loadingService.setGlobalLoading(false)
      })
    }
  }
}
</script>
