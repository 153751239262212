<template>
  <validation-observer v-slot="{ handleSubmit }" tag="div">
    <v-form @submit.prevent="handleSubmit(sendCodeToOwner)">
      <template #header>
        <v-form-row>
          <h1 class="password-recovery__title">Вход/регистрация</h1>
          <div class="password-recovery__desc">
            Если к вашему номеру телефона привязан один из объектов, после регистрации вам станет доступна вся
            аналитика.
          </div>
        </v-form-row>
      </template>
      <template #default>
        <v-form-row>
          <v-form-field label="НОМЕР ТЕЛЕФОНА" :error-message="backendErrorMessage" rules="required">
            <template #default="{ validationErrors }">
              <v-input-phone v-model="phone" autofocus :is-error="!!validationErrors.length || !!backendErrorMessage" />
            </template>
          </v-form-field>
        </v-form-row>
      </template>
      <template #footer>
        <v-button primary type="submit">Выслать код подтверждения</v-button>
      </template>
    </v-form>
  </validation-observer>
</template>

<script>
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VButton from '@/components/common/VButton.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import { authService } from '@/services/http'
import { loadingService } from '@/services/loading'

export default {
  name: 'SendPhone',
  components: {
    VForm,
    VFormRow,
    VFormField,
    VInputPhone,
    VButton
  },
  data() {
    return {
      phone: '',
      backendErrorMessage: ''
    }
  },
  methods: {
    sendCodeToOwner() {
      loadingService.setGlobalLoading(true)
      authService
        .sendCodeToOwnerPhone(this.phone)
        .then(() => this.$emit('submitted', this.phone))
        .catch(error => {
          if (error.status === 400) {
            this.backendErrorMessage = 'Телефон не найден'
          } else {
            this.errorBackendMessage = 'Неизвестная ошибка'
            throw error
          }
        })
        .finally(() => {
          loadingService.setGlobalLoading(false)
        })
    }
  }
}
</script>
